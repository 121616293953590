$primary: #D8B280;
$secondary: #2D2D2D;
$headings-font-family: Times New Roman, serif;
$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1140px
) !default;
$font-weight-base: 300;
$font-weight-bolder: 500;
@import "boostrap/bootstrap";

.container {
  @include media-breakpoint-up(lg) {

    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}
